import keymirror from 'keymirror';

const ANALYTICS_BASE_URL = '/';

export const ACTIVE_CONCEPT_COLORS = {
  Pink: '#ff379c',
  Red: '#ff0032',
  Orange: '#f07600',
  Yellow: '#ffbb2a',
  Green: '#15cf20',
  Blue: '#00aaff',
  Purple: '#7d27bc',
  Grey: '#aaaaaa'
};

export const COLORS = [
  ACTIVE_CONCEPT_COLORS.Blue,
  ACTIVE_CONCEPT_COLORS.Green,
  ACTIVE_CONCEPT_COLORS.Yellow,
  ACTIVE_CONCEPT_COLORS.Orange,
  ACTIVE_CONCEPT_COLORS.Red,
  ACTIVE_CONCEPT_COLORS.Pink,
  ACTIVE_CONCEPT_COLORS.Purple,
  ACTIVE_CONCEPT_COLORS.Grey
];

export const AlertTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
};

export const ActionTypes = keymirror({
  ACTIVE_CONCEPT_UPDATE: null,
  ACTIVE_CONCEPT_BULK_RECOLOR: null,
  ACTIVE_CONCEPTS_REMOVE: null,
  ACTIVE_CONCEPTS_REPLACE: null,
  ACTIVE_CONCEPTS_ADD: null,
  ACTIVE_CONCEPTS_REORDER: null,
  ACTIVE_LIST_NAME_UPDATE: null,
  ACTIVE_LIST_RESTORE: null,
  CONCEPT_SELECT: null,
  CONCEPT_LIST_OPEN: null,
  FILTER_SELECT: null,
  FILTER_INFO_SPINNER_HIDE: null,
  FILTER_INFO_SPINNER_SHOW: null,
  NOTIFICATION: null,
  PROJECT_EDIT: null,
  PROJECT_EDIT_NEXT_BUILD_LANGUAGE: null,
  PROJECT_EDIT_COLLOCATION: null,
  PROJECT_LOAD: null,
  SHARED_VIEW_DELETE: null,
  SHARED_VIEW_SAVE: null,
  SHARED_VIEWS_GET: null,
  SHARED_CONCEPT_LISTS_LOAD: null,
  SHARED_CONCEPT_LIST_ADD: null,
  SHARED_CONCEPT_LIST_ADD_UNDO: null,
  SHARED_CONCEPT_LIST_SAVE_CONCEPTS: null,
  SHARED_CONCEPT_LIST_SAVE_CONCEPTS_UNDO: null,
  SIDE_PANEL_SELECT_TAB: null,
  WORKSPACES_GET: null,
  UPDATE_FILTER_COUNT: null,
  SELECTED_VIEW_UPDATE: null,
  DOC_SEARCH_RESULTS_LOAD_MORE: null,
  DOC_MARKED_FOR_DELETE: null,
  LOAD_DOCS: null,
  FETCH_DRIVERS_AND_SENTIMENTS: null,
});

export const ErrorTypes = keymirror({
  PROJECT_NOT_READY: null,
  PROJECT_EMPTY: null,
  PROJECT_NEEDS_UPDATING: null,
  UNKNOWN: null
});

export const MetadataTypes = {
  CATEGORY: 'string',
  DATE: 'date',
  NUMBER: 'number',
  SCORE: 'score'
};

export const ConceptTypes = {
  OR: 'or',
  AND: 'and',
};

export const RequestStatuses = keymirror({
  FULFILLED: null,
  PENDING: null,
  REJECTED: null
});

export const RoutePatterns = {
  PROJECT_EXPLORER: '/projects/:workspaceId/:projectId',
  HIGHLIGHTS: '/projects/:workspaceId/:projectId/highlights',
  GALAXY: '/projects/:workspaceId/:projectId/galaxy',
  ONLY_GALAXY: '/projects/:workspaceId/:projectId/only_galaxy',
  VOLUME: '/projects/:workspaceId/:projectId/volume',
  DRIVERS: '/projects/:workspaceId/:projectId/drivers',
  SENTIMENT: '/projects/:workspaceId/:projectId/sentiment',
  CREATE_PROJECT: '/upload',
  UPLOAD_DATA: '/upload/:workspaceId/:projectId',
  UPLOAD_STREAM_DATA: '/upload/stream',
  UPDATE_PROJECT_STREAM_DATA: '/upload/stream-edit/:workspaceId/:projectId',
  SETTINGS: '/settings',
  PROFILE: '/settings/profile',
  PASSWORD: '/settings/password',
  WORKSPACE_SETTINGS: '/settings/workspaces/:workspaceId',
  WORKSPACE_ADD_USER: '/settings/workspaces/:workspaceId/add-user',
  WORKSPACE_USAGE: '/settings/usage/:workspaceId/:billingPeriodIndex?',
  ORGANIZATION_SETTINGS: '/settings/organizations/:organizationId',
  ORGANIZATION_CREATE_WORKSPACE:
    '/settings/organizations/:organizationId/create-workspace',
  ORGANIZATION_USAGE:
    '/settings/organizations/:organizationId/usage/:billingPeriodIndex?',
  TOKENS: '/settings/tokens',
  NEW_TOKEN: '/settings/tokens/new',
  PROJECT_MANAGEMENT: '/projects/:workspaceId/:projectId/manage',
  DASHBOARD: 'https://dashboards.luminoso.com/app'
};

export const URLs = {
  ANALYTICS_BASE: ANALYTICS_BASE_URL,
  ANALYTICS_LOGIN: `${ANALYTICS_BASE_URL}login.html`,
  DATA_FORMATTING_GUIDE:
    'https://www.luminoso.com/getting-started-with-daylight/formatting-your-data',
  DATASET_UPLOAD_HELP:
    'https://www.luminoso.com/getting-started-with-daylight/uploading-a-dataset',
  MAIN_SUPPORT: 'https://support.luminoso.com/'
};

export const REACT_QUERY_CLIENT_CONFIG = {
  defaultOptions: {
    queries: {
      cacheTime: Math.Infinity,
      refetchOnWindowFocus: false,
      // Don't retry requests that throw errors. For now, our API framework is
      // handling failing requests (e.g. 429 errors).
      retry: false
    }
  }
};

export const UPLOAD_DATA_NUMBER_UNWANTED_CHARACTERS = ['%'];
