import { EventEmitter } from 'events';

import _ from 'lodash';

import Dispatcher from '../Dispatcher';
import { ActionTypes, RequestStatuses } from '../constants';
import { Concept } from '../classes/Concepts';
import storage from '../utils/storage';

export const initialState = {
  workspaces: [],
  userDefaultWorkspaceId: '',
  notification: null,
  selection: null,
  topConcepts: [],
  activeConcepts: [],
  activeConceptListName: null,
  sharedConceptLists: null,
  selectedSidePanelTab: 'configure',
  metadata: [],
  filterCount: 0,
  outlierFilterCount: 0,
  filterIsLoading: false,
  totalDocCount: 0,
  searchInProgress: false,
  docSearchResults: null,
  conceptSearchResults: null,
  suggestions: [],
  isOpeningConceptList: false,
  projectError: null,
  projectHasLoaded: false,
  projectLanguage: '',
  collocationNumbers: 5,
  project: null,
  sharedViews: {},
  selectedView: null,
  coverageLoading: false,
  tooltipLoading: false,
  username: storage.load(storage.KEYS.user, null),
  docSearchLoading: false,
  deletedDocs: [],
  filterDocCount: 0,
  selectedConcept: null
};
let _state = _.clone(initialState);

const reducer = (state, { type, payload, status }) => {
  if (!state) {
    state = initialState;
  }
  const update = updater => ({ ...state, ...updater });
  switch (type) {
    case ActionTypes.WORKSPACES_GET:
      switch (status) {
        case RequestStatuses.FULFILLED: {
          const { workspaces, defaultWorkspaceId } = payload;
          return update({
            workspaces,
            userDefaultWorkspaceId: defaultWorkspaceId
          });
        }
      }
      break;

    case ActionTypes.ACTIVE_LIST_NAME_UPDATE:
      return update({ activeConceptListName: payload ?? null });

    case ActionTypes.CONCEPT_SELECT:
      switch (status) {
        case RequestStatuses.PENDING: {
          const { concept } = payload;

          // check to see if the selection has actually changed
          if (state.selection) {
            if (_.isEqual(concept, state.selection)) {
              return;
            }
          }

          return update({
            selection: getUpdatedSelection(concept, state.activeConcepts),
            searchInProgress: true,
            docSearchResults: null,
            conceptSearchResults: null
          });
        }

        case RequestStatuses.FULFILLED: {
          const { concept, results } = payload;

          return update({
            selection: getUpdatedSelection(concept, state.activeConcepts),
            searchInProgress: false,
            docSearchResults: results.docs ?? null,
            conceptSearchResults: results.topConcepts || null,
            filterDocCount: results.filterDocCount
          });
        }

        case RequestStatuses.REJECTED: {
          return update({
            selection: null,
            searchInProgress: false,
            docSearchResults: null,
            conceptSearchResults: null
          });
        }
      }
      break;

    case ActionTypes.NOTIFICATION:
      if (payload.type && payload.description) {
        return update({
          notification: {
            type: payload.type,
            description: payload.description
          }
        });
      }
      break;

    case ActionTypes.PROJECT_LOAD:
      switch (status) {
        case RequestStatuses.PENDING: {
          const { concept, projectId } = payload;

          return update({
            projectHasLoaded: false,
            project:
              state.project?.project_id === projectId ? state.project : null,
            selection: getUpdatedSelection(concept, state.activeConcepts),
            searchInProgress: true,
            docSearchResults: null,
            conceptSearchResults: null,
            sharedViews: {}
          });
        }

        case RequestStatuses.FULFILLED: {
          const {
            project,
            topConcepts,
            activeConcepts,
            activeConceptListName,
            suggestions,
            metadata,
            docSearchResults,
            conceptSearchResults,
            concept,
            filterCount,
            sharedConceptLists,
            sharedViews,
            filterDocCount
          } = payload;
          return update({
            metadata,
            project,
            selection: getUpdatedSelection(concept, activeConcepts),
            searchInProgress: false,
            docSearchResults: docSearchResults ?? null,
            conceptSearchResults,
            totalDocCount: project.totalDocCount,
            filterCount,
            filterDocCount,
            topConcepts,
            activeConcepts,
            activeConceptListName,
            suggestions,
            projectError: null,
            projectHasLoaded: true,
            projectLanguage: project.language,
            collocationNumbers: project.last_build_info?.max_ngram_length ? project.last_build_info?.max_ngram_length : 5,
            sharedConceptLists,
            sharedViews
          });
        }

        case RequestStatuses.REJECTED: {
          return update({
            projectError: payload.error,
            projectHasLoaded: false,
            project: payload.project ?? null,
            selection: null,
            searchInProgress: false,
            docSearchResults: null,
            conceptSearchResults: null,
            sharedViews: {}
          });
        }
      }
      break;

    case ActionTypes.SHARED_CONCEPT_LISTS_LOAD:
      if (status === RequestStatuses.FULFILLED) {
        const { sharedConceptLists } = payload;
        return update({ sharedConceptLists });
      }
      break;

    case ActionTypes.SHARED_CONCEPT_LIST_ADD:
      switch (status) {
        case RequestStatuses.PENDING:
          return update({ isOpeningConceptList: true });
        case RequestStatuses.FULFILLED: {
          const { conceptList } = payload;
          return update({
            ...getUpdateForActiveConceptListChanges(state, conceptList),
            isOpeningConceptList: false,
            sharedConceptLists: [...state.sharedConceptLists, conceptList]
          });
        }
        case RequestStatuses.REJECTED:
          return update({ isOpeningConceptList: false });
      }
      break;

    case ActionTypes.SHARED_CONCEPT_LIST_ADD_UNDO:
      switch (status) {
        case RequestStatuses.PENDING:
          return update({ isOpeningConceptList: true });
        case RequestStatuses.FULFILLED: {
          const { activeConcepts, activeConceptListName, createdListId } =
            payload;
          return update({
            ...getUpdateForActiveConceptListChanges(state, {
              name: activeConceptListName,
              concepts: activeConcepts
            }),
            isOpeningConceptList: false,
            sharedConceptLists: state.sharedConceptLists.filter(
              cl => cl.concept_list_id !== createdListId
            )
          });
        }
        case RequestStatuses.REJECTED:
          return update({ isOpeningConceptList: false });
      }
      break;

    case ActionTypes.SHARED_CONCEPT_LIST_SAVE_CONCEPTS:
      if (status === RequestStatuses.FULFILLED) {
        const { savedList } = payload;
        const updatedSharedLists = [
          ..._.reject(state.sharedConceptLists, {
            concept_list_id: savedList.concept_list_id
          }),
          savedList
        ];
        return update({
          activeConceptListName: savedList.name,
          sharedConceptLists: updatedSharedLists
        });
      }
      break;

    case ActionTypes.SHARED_CONCEPT_LIST_SAVE_CONCEPTS_UNDO:
      if (status === RequestStatuses.FULFILLED) {
        const { listName, oldConcepts } = payload;
        const updatedSharedLists = state.sharedConceptLists.map(list =>
          list.name === listName ? { ...list, concepts: oldConcepts } : list
        );
        return update({ sharedConceptLists: updatedSharedLists });
      }
      break;

    case ActionTypes.ACTIVE_CONCEPTS_ADD: {
      const { activeConcepts } = payload;
      const newActiveConcepts = [...activeConcepts, ...state.activeConcepts];

      return update({
        activeConcepts: newActiveConcepts,
        // update state.selection if this concept is selected
        selection: getUpdatedSelection(state.selection, newActiveConcepts)
      });
    }

    case ActionTypes.DOC_MARKED_FOR_DELETE: {
      return update({
        deletedDocs: payload
      });
    }

    case ActionTypes.ACTIVE_CONCEPTS_REMOVE:
      if (status === RequestStatuses.FULFILLED) {
        const { sharedConceptIds } = payload;
        const [deletedConcepts, newActiveConcepts] = _.partition(
          state.activeConcepts,
          ac => sharedConceptIds.includes(ac.sharedConceptId)
        );

        if (deletedConcepts.length === 0) {
          break;
        }

        let selection = state.selection;
        if (sharedConceptIds.includes(selection?.sharedConceptId)) {
          selection = selection.deactivate();
        }

        return update({
          activeConcepts: newActiveConcepts,
          selection
        });
      }
      break;

    case ActionTypes.ACTIVE_CONCEPT_BULK_RECOLOR: {
      const { concepts } = payload;

      const activeConcepts = state.activeConcepts.map(concept => {
        const { sharedConceptId } = concept;
        return _.find(concepts, { sharedConceptId }) ?? concept;
      });

      // Update the selection's color if it was part of the update
      let selection = state.selection;
      if (selection?.sharedConceptId) {
        const { sharedConceptId } = selection;
        const updatedSelection = _.find(concepts, { sharedConceptId });
        if (updatedSelection) {
          // Update the existing selection (instead of "updatedSelection") in
          // order to preserve the selection's match count information
          selection = selection.update({ color: updatedSelection.color });
        }
      }

      return update({ selection, activeConcepts });
    }

    case ActionTypes.ACTIVE_CONCEPT_UPDATE: {
      const { updatedConcept } = payload;
      const updatingSelection =
        state.selection?.isActive &&
        state.selection.sharedConceptId === updatedConcept?.sharedConceptId;

      switch (status) {
        case RequestStatuses.PENDING: {
          // Updating the selection is going to require an additional call to
          // the selectConcept action in order to update any selection-related
          // things that have become outdated (e.g. matching documents). That
          // call can only happen after we've committed the changes to the
          // selection.
          //
          // This preemptively sets searchInProgress so that the relevant parts
          // of the UI can go into a loading state without first needing to
          // resolve the API request that updates the active concepts.
          if (updatingSelection) {
            return update({ searchInProgress: true });
          }
          break;
        }
        case RequestStatuses.FULFILLED: {
          const index = state.activeConcepts.findIndex(
            ac => ac.sharedConceptId === updatedConcept.sharedConceptId
          );

          const updatedActiveConcepts = state.activeConcepts.slice(); // shallow-clone the existing array
          updatedActiveConcepts.splice(index, 1, updatedConcept);

          // update state.selection if this concept is selected
          const selection = updatingSelection
            ? updatedConcept
            : state.selection;

          // update state.hovered if this concept is hovered
          const hovered =
            state.hovered?.isActive &&
            state.hovered?.sharedConceptId === updatedConcept.sharedConceptId
              ? updatedConcept
              : state.hovered;

          return update({
            hovered,
            selection,
            activeConcepts: updatedActiveConcepts
          });
        }
        case RequestStatuses.REJECTED: {
          if (updatingSelection) {
            return update({ searchInProgress: false });
          }
        }
      }
      break;
    }

    case ActionTypes.ACTIVE_CONCEPTS_REORDER:
      if (
        [RequestStatuses.FULFILLED, RequestStatuses.PENDING].includes(status)
      ) {
        const { sharedConceptIds } = payload;
        const oldIdOrder = state.activeConcepts.map(ac => ac.sharedConceptId);

        // don't update when the order hasn't changed
        if (_.isEqual(oldIdOrder, sharedConceptIds)) {
          return;
        }

        return update({
          activeConcepts: sharedConceptIds.map(sharedConceptId =>
            _.find(state.activeConcepts, { sharedConceptId })
          )
        });
      }
      break;

    case ActionTypes.ACTIVE_CONCEPTS_REPLACE: {
      switch (status) {
        case RequestStatuses.PENDING: {
          return update({ isOpeningConceptList: true });
        }
        case RequestStatuses.FULFILLED: {
          const { activeConcepts } = payload;
          return update({
            selection: getUpdatedSelection(state.selection, activeConcepts),
            activeConcepts,
            isOpeningConceptList: false
          });
        }
        case RequestStatuses.REJECTED: {
          return update({ isOpeningConceptList: false });
        }
      }
      break;
    }

    case ActionTypes.CONCEPT_LIST_OPEN:
    case ActionTypes.ACTIVE_LIST_RESTORE:
      switch (status) {
        case RequestStatuses.PENDING: {
          return update({ isOpeningConceptList: true });
        }
        case RequestStatuses.FULFILLED: {
          const { activeConcepts, activeConceptListName } = payload;

          return update({
            selection: getUpdatedSelection(state.selection, activeConcepts),
            activeConcepts,
            activeConceptListName,
            isOpeningConceptList: false
          });
        }
        case RequestStatuses.REJECTED: {
          return update({ isOpeningConceptList: false });
        }
      }
      break;

    case ActionTypes.FILTER_INFO_SPINNER_HIDE:
      return update({ filterIsLoading: false, coverageLoading: false });

    case ActionTypes.FILTER_INFO_SPINNER_SHOW:
      return update({ filterIsLoading: true, coverageLoading: true });

    case ActionTypes.FILTER_SELECT:
      switch (status) {
        case RequestStatuses.PENDING: {
          return update({ filterIsLoading: true });
        }

        case RequestStatuses.REJECTED:
          return update({
            filterIsLoading: false
          });

        case RequestStatuses.FULFILLED: {
          const { filterCount, topConcepts } = payload;
          return update({
            filterCount,
            filterIsLoading: false,
            topConcepts
          });
        }
      }
      break;
    case ActionTypes.PROJECT_EDIT: {
      const { name, description } = payload;
      return update({ project: { ...state.project, name, description } });
    }

    case ActionTypes.PROJECT_EDIT_NEXT_BUILD_LANGUAGE: {
      const { nextBuildLanguage } = payload;
      return update({
        project: {
          ...state.project,
          next_build_language: nextBuildLanguage
        }
      });
    }

    case ActionTypes.PROJECT_EDIT_COLLOCATION: {
      const { collocation } = payload;
      return update({
        project: {
          ...state.project,
          next_build_collocation: +collocation
        }
      });
    }

    case ActionTypes.SIDE_PANEL_SELECT_TAB: {
      return update({ selectedSidePanelTab: payload });
    }

    case ActionTypes.SHARED_VIEWS_GET: {
      const { sharedViews } = payload;
      return update({ sharedViews });
    }

    case ActionTypes.SHARED_VIEW_SAVE: {
      const { sharedView } = payload;
      const sharedViews = {
        ..._.omitBy(state.sharedViews, { name: sharedView.name }),
        [sharedView.shared_view_id]: sharedView
      };
      return update({ sharedViews });
    }

    case ActionTypes.SHARED_VIEW_DELETE: {
      const { sharedViewId } = payload;
      const sharedViews = {
        ..._.omitBy(state.sharedViews, { shared_view_id: sharedViewId })
      };
      return update({ sharedViews });
    }
    case ActionTypes.UPDATE_FILTER_COUNT: {
      switch (status) {
        case RequestStatuses.PENDING: {
          return update({ coverageLoading: true });
        }

        case RequestStatuses.REJECTED:
          return update({
            coverageLoading: false
          });

        case RequestStatuses.FULFILLED: {
          const { outlierFilterCount } = payload;
          return update({
            coverageLoading: false,
            outlierFilterCount
          });
        }
      }
      break;
    }
    case ActionTypes.SELECTED_VIEW_UPDATE:
      return {
        ...state,
        selectedView: payload
      };

    case ActionTypes.DOC_SEARCH_RESULTS_LOAD_MORE: {
      switch (status) {
        case RequestStatuses.PENDING:
          return update({
            docSearchLoading: true
          });
        case RequestStatuses.FULFILLED: {
          const { additionalDocs } = payload;
          return update({
            docSearchLoading: false,
            docSearchResults: [...state.docSearchResults, ...additionalDocs.matches],
            filterDocCount: additionalDocs.filterDocCount
          });
        }
        case RequestStatuses.REJECTED:
          return update({
            docSearchLoading: false
          });
      }
      break;
    }

    case ActionTypes.LOAD_DOCS: {
      switch (status) {
        case RequestStatuses.PENDING:
          return update({
            docSearchLoading: true
          });
        case RequestStatuses.FULFILLED: {
          const { docs } = payload;
          return update({
            docSearchLoading: false,
            docSearchResults: docs.matches,
            filterDocCount: docs.filterDocCount
          });
        }
        case RequestStatuses.REJECTED:
          return update({
            docSearchLoading: false
          });
      }
      break;
    }
    case ActionTypes.FETCH_DRIVERS_AND_SENTIMENTS:
      switch (status) {
        case RequestStatuses.PENDING:
          return update({ tooltipLoading: true });
        case RequestStatuses.FULFILLED:
          // eslint-disable-next-line no-case-declarations
          const { combinedData } = payload;
        return update({
            selectedConcept: combinedData,
            tooltipLoading: false
          });
        case RequestStatuses.REJECTED:
          return update({ tooltipLoading: false });
      }
      break;

  }
};

const CHANGE_EVENT = 'STORE_CHANGE';
const emitter = new EventEmitter();
// This is only exported for the purposes of mocking in tests
export const _emitChange = () => emitter.emit(CHANGE_EVENT);

Dispatcher.register(action => {
  const nextState = reducer(_state, action);
  if (nextState && nextState !== _state) {
    _state = nextState;
    _emitChange();
  }
});

export function addChangeListener(cb) {
  emitter.on(CHANGE_EVENT, cb);
}

export function removeChangeListener(cb) {
  emitter.removeListener(CHANGE_EVENT, cb);
}

export const getState = () => _state;

function getUpdatedSelection(concept, activeConcepts) {
  if (!concept) {
    return null;
  }
  const matchingActiveConcept = activeConcepts.find(ac =>
    Concept.areTextsEqual(ac, concept)
  );
  if (matchingActiveConcept) {
    return concept.update(matchingActiveConcept);
  }
  return concept.deactivate();
}

const getUpdateForActiveConceptListChanges = (state, activeConceptList) => {
  const activeConcepts = activeConceptList.concepts;
  return {
    activeConcepts,
    selection: getUpdatedSelection(state.selection, activeConcepts),
    activeConceptListName: activeConceptList.name
  };
};
