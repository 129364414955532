import { ConceptTypes } from '../constants';

export const getById = (concepts, sharedConceptId) =>
  concepts.find(concept => concept.sharedConceptId === sharedConceptId);

export function extractSearchFromConcept(concept) {
  if (!concept) return null;

  const texts = concept.texts
    ? concept.texts.flatMap(text =>
      text.includes('&')
        ? text.split('&').map(part => part.trim())
        : text.trim()
    )
    : [];

  const type = concept.texts?.some(text => text.includes('&')) ? ConceptTypes.AND : concept.type || ConceptTypes.OR;

  return { texts, type };
}