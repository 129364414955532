import _ from 'lodash';

import { camelCaseKeys } from '../utils/common';
import { unpack } from '../utils/pack64';
import { normalize, dot } from '../utils/v';
import { getFeatureFlags } from '../featureFlagsSingleton';
import { ConceptTypes } from '../constants';

function updateConceptName(currentTexts, newTexts, currentName) {
  let name = '';
  const currentTextsJoined = currentTexts.join(', '),
    newTextsJoined = newTexts.join(', ');

  currentName === currentTextsJoined
    ? (name = newTextsJoined)
    : (name = currentName);

  return name;
}

export class Concept {
  constructor(config) {
    const camelCased = camelCaseKeys(config);

    for (const key of Object.keys(camelCased)) {
      this[key] = camelCased[key];
    }
    this.exactTermIds = this.exactTermIds ?? [];
    if (this.vector) {
      delete this.vector;
    }

    if (!this.vectors) {
      this.vectors = [];
    }
    if (!this.type) {
      this.type = ConceptTypes.OR;
    }
    this.galaxyVectors = this.vectors.map(makeGalaxyVector);

    if (!this.isActive) {
      this.name = this.toString();
    }
  }

  // eslint-disable-next-line no-unused-vars
  update(updates) {
    throw new Error('This method should be implemented in subclasses');
  }

  // eslint-disable-next-line no-unused-vars
  addTexts(string) {
    throw new Error('This method should be implemented in subclasses');
  }

  // eslint-disable-next-line no-unused-vars
  removeTexts(string) {
    throw new Error('This method should be implemented in subclasses');
  }

  toString() {
    throw new Error('This method should be implemented in subclasses');
  }

  toJSON() {
    return { texts: this.texts, type: ConceptTypes.OR };
  }

  equals(otherConcept) {
    return _.isEqual(new Set(this.texts), new Set(otherConcept?.texts));
  }

  hash() {
    throw new Error('This method should be implemented in subclasses');
  }

  get isActive() {
    return this.sharedConceptId !== undefined || this.type === 'and';
  }

  getAssociation(concept) {
    if (_.isEmpty(this.vectors) || _.isEmpty(concept.vectors)) {
      return;
    }

    let highest = -1;
    for (let vecA of this.vectors) {
      for (let vecB of concept.vectors) {
        const score = dot(vecA, vecB);
        if (score > highest) {
          highest = score;
        }
      }
    }
    return highest;
  }

  /**
   * An association score that forms broader clusters, using the lower-
   * dimensional galaxy vectors instead of the complete vectors.
   */
  getClusteryAssociation(concept) {
    if (_.isEmpty(this.galaxyVectors) || _.isEmpty(concept.galaxyVectors)) {
      return;
    }

    let highest = -1;
    for (let galaxyVecA of this.galaxyVectors) {
      for (let galaxyVecB of concept.galaxyVectors) {
        const score = dot(galaxyVecA, galaxyVecB);
        if (score > highest) {
          highest = score;
        }
      }
    }

    return highest;
  }

  /**
   * Formats a concept for submission to the active concept endpoints.
   */
  formatForSaving() {
    return {
      texts: this.texts,
      name: this.name,
      color: this.color,
      shared_concept_id: this.sharedConceptId,
      type: this.type
    };
  }

  /**
   * Return an equivalent inactive version of the concept.
   */
  deactivate() {
    const inactive = this.update({ sharedConceptId: undefined });
    delete inactive.sharedConceptId; // strip the property completely
    return inactive;
  }

  static fromJSON(config) {
    const type = config.type || ConceptTypes.OR;
    if (type === ConceptTypes.AND) {
      let texts = normalizeTexts(config.texts)
      const vectors = config.vectors?.map(unpack);
      const name = texts.join(type === ConceptTypes.AND ? ' & ' : ', ');
      texts = texts.flatMap(text => text.split('&').map(t => t.trim()));
      if (texts.length > 1 && getFeatureFlags().boolean_search) {
        return new BooleanConcept({ ...config, texts, vectors, type, name });
      }
    }
    const texts = normalizeTexts(config.texts);
    const vectors = config.vectors?.map(unpack);

    if (texts.length > 1 && getFeatureFlags().boolean_search) {
      return new BooleanConcept({ ...config, texts, vectors });
    }
    return new VectorConcept({ ...config, texts, vectors });
  }

  /**
   * Takes a comma-separated string of text and produces a Concept object
   * without the details normally provided by the server. Will return null
   * if passed null or a string with no texts.
   *
   * @param {string} string
   * @returns {null|Concept}
   */
  static fromString(string) {
    validate(string ?? '', true);
    const type = string?.includes('&') ? ConceptTypes.AND : ConceptTypes.OR;
    const texts = searchToTexts(string ?? '');
    if (texts.length === 0) {
      return null;
    }
    return Concept.fromJSON({ texts,type });
  }

  /**
   * Takes a Concept and returns a string representation suitable for
   * serializing to a URL search or displaying in a search box.
   *
   * @param {null|Concept} concept
   */
  static toString(concept) {
    return concept?.toString() ?? '';
  }

  /**
   * Checks to see if two concept objects contain the same texts.
   *
   * @param {Concept} [a] A concept.
   * @param {Concept} [b] Another concept.
   * @return {boolean} True if neither exists or both have the same
   * texts.
   */
  static areTextsEqual(a, b) {
    if (a == null && b == null) {
      return true;
    }
    return (a?.equals(b) && a.type === b.type) ?? false;
  }

  /**
   * Get a MatchCount object from a Concept
   *
   * @param {Concept} concept
   * @returns {Object.<string, number>} - object with exact and total
   */
  static getMatchCount(concept) {
    return {
      exact: concept.exactMatchCount,
      total: concept.matchCount
    };
  }
}

export class VectorConcept extends Concept {
  update(updates) {
    return new VectorConcept({ ...this, ...updates });
  }

  addTexts(string) {
    validate(string, false);
    const texts = _.uniq([...this.texts, ...searchToTexts(string)]);

    if (texts.length > 1 && getFeatureFlags().boolean_search) {
      const name = updateConceptName(this.texts, texts, this.name);
      return new BooleanConcept(_.omit({ ...this, name, texts }, ['vectors']));
    }

    return new VectorConcept({ ...this, texts });
  }

  removeTexts(string) {
    const removed = searchToTexts(string);
    const texts = _.difference(this.texts, removed);
    return new VectorConcept({ ...this, texts });
  }

  toString() {
    return this.texts.join(', ');
  }

  hash() {
    return this.sharedConceptId ?? (this.exactTermIds ? this.exactTermIds.join(',') : '');
  }

  get boolean() {
    return false;
  }

  get included() {
    return this.texts;
  }

  get excluded() {
    return [];
  }
}

export class BooleanConcept extends Concept {
  constructor(config) {
    super(config);
    this.excludedTermIds = this.excludedTermIds ?? [];
  }
  update(updates) {
    return new BooleanConcept({ ...this, ...updates });
  }

  addTexts(string) {
    validate(string, false);

    const texts = _.uniq([...this.texts, ...searchToTexts(string)]),
      name = updateConceptName(this.texts, texts, this.name);

    return new BooleanConcept({ ...this, name, texts });
  }

  removeTexts(string) {
    const removed = searchToTexts(string);
    const texts = _.difference(this.texts, removed),
      name = updateConceptName(this.texts, texts, this.name);

    if (texts.length === 1) {
      return new VectorConcept(
        _.omit({ ...this, name, texts }, ['vectors', 'galaxyVectors'])
      );
    }
    return new BooleanConcept({ ...this, name, texts });
  }

  toString() {
    return this.texts.join(', ');
  }

  hash() {
    if (this.sharedConceptId != null) {
      return this.sharedConceptId;
    }
    return [...this.exactTermIds, ...this.excludedTermIds.map(t => '-' + t)]
      .sort()
      .join(',');
  }

  get boolean() {
    return true;
  }

  get included() {
    return this.texts.filter(text => !text.startsWith('-'));
  }

  get excluded() {
    const included = this.included;
    return this.texts.filter(text => !included.includes(text));
  }
}

function normalizeTexts(texts) {
  return texts.map(t => t.trim()).filter(t => t !== '');
}

function searchToTexts(search) {
  return normalizeTexts(search.split(','));
}

function makeGalaxyVector(vector) {
  // In QuickLearn 2, we want to use high-dimensional vectors for
  // measuring conceptual matches, but lower-dimensional vectors in
  // the galaxy visualization. We create the "galaxy vector" by truncating
  // the concept's vector to 10 dimensions and normalizing.
  return normalize(vector.slice(0, 10));
}

/**
 * Validate a string of user-entered text meant to define a concept.
 *
 * @param {string} string - the entered text
 * @param {boolean} isNewConcept - a boolean indicating whether this should
 *                  be evaluated as a new concept or as texts added to an
 *                  existing concept. (In the latter case, it's okay for them
 *                  to all be excluded).
 */
function validate(string, isNewConcept) {
  if (!getFeatureFlags().boolean_search) {
    return;
  }
  let texts = string.split(',').map(t => t.trim());
  // Check if all texts are blank (this comes first because if this happens,
  // none of the other errors can apply).
  if (texts.length > 1 && texts.every(text => text === '')) {
    throw new Error(genericErrorMessage);
  }
  // Any empty texts remaining after the above check will just be silently
  // filtered out, so we can ignore them for validation purposes.
  texts = texts.filter(text => text !== '');
  // Now divide the texts into two groups:
  // Well-formed texts have an optional operator (even if it's one we don't
  // support) and then a concept (i.e., not an operator). These can be
  // evaluated based on their _content_, for the "unsupported operator" and
  // "only excluded texts" errors.
  // Ill-formed texts are either just an operator or anything that starts
  // with multiple operators. If nothing uses an unsupported operator, we
  // should raise a generic validation error if there are any ill-formed
  // texts.
  const [wellFormedTexts, illFormedTexts] = _.partition(
    texts,
    text => /^[+-][^+-]/.test(text) || /^[^+-]/.test(text)
  );
  if (
    wellFormedTexts.some(text => text.startsWith('+')) ||
    (wellFormedTexts.length > 0 &&
      illFormedTexts.some(text => text.startsWith('+')))
  ) {
    throw new Error(unsupportedOperatorMessage);
  }
  if (
    illFormedTexts.length > 0 ||
    (isNewConcept &&
      wellFormedTexts.length > 0 &&
      wellFormedTexts.every(text => text.startsWith('-')))
  ) {
    throw new Error(genericErrorMessage);
  }
}

const genericErrorMessage = `Searches must have a concept that doesn't start with "-". Extra or unattached symbols ("-" ",") are invalid.`;
const unsupportedOperatorMessage = `Using a "+" isn't valid. Use a "," or "-" symbol instead.`;
