import _ from 'lodash';

import * as apiV5 from './apiV5';
import { deleteWithFullUrl, getWithFullUrl, postWithFullUrl } from './apiV5';
import { COLORS, MetadataTypes } from '../constants';
import { MetadataField } from '../classes/MetadataFields';
import { Concept } from '../classes/Concepts';
import { Doc } from '../classes/Docs';
import { camelCaseKeys } from './common';
import { getFeatureFlags } from '../featureFlagsSingleton';
import { ConceptManagementInformation } from '../project_management/ConceptManagementInformation';
import { save } from './storage';
import { OutlierConstraint } from '../classes/Constraints';
import { extractSearchFromConcept } from './conceptUtils';

export function login(username, password, acknowledged = false) {
  return apiV5
    .post('/login/', { username, password, acknowledged })
    .then(({ token }) => save('auth_token', token));
}

export function logout() {
  return apiV5.post('/logout/');
}

export function getProfile() {
  return apiV5.get('/profile/');
}

export function recoverPassword(email) {
  return apiV5.post('/profile/password/reset', { email });
}

export function resetPassword(token, password) {
  return apiV5.post('/profile/password/', { token, password });
}

const getExportFilename = (projectName, exportName) => {
  return `${projectName.substring(0, 25)}_${exportName}.xlsx`;
};

export function getAPIStatus() {
  return apiV5.get('/status/', { include_secrets: true });
}

export function getUserFlags() {
  return apiV5.get('/profile/flags/');
}

export function getProjects() {
  return apiV5.get('/projects/');
}

export const getDashboardProjects = () => {
  return new Promise((resolve, reject) => {
    getWithFullUrl(`${process.env.DASHBOARDS_URL_STREAM_DATA}/projects`)
      .then(response => {
        if (response?.status === 'success' && response.result) {
          resolve(response.result);
        } else {
          throw new Error('Invalid response structure or unsuccessful status');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

/**
 * Get a project by its ID.
 *
 * @param {string} projectId - the project's ID
 * @param {string[]} [fields] - List of fields of the project to return. Leave
 *                              empty if you want to get the entire project
 * @return {Object} - An object containing either the project itself or a subset
 *                    of its properties
 */
export function getProject(projectId, fields, updateLastAccessTime) {
  const params = {};

  if (fields) {
    params.fields = fields;
  }
  if (updateLastAccessTime) {
    params.update_last_access_time = updateLastAccessTime;
  }

  return apiV5.get(`/projects/${projectId}/`, params).then(project => {
    project.totalDocCount = project.document_count;
    project.mostRecentJobFailed =
      project.last_build_info.stop_time && !project.last_build_info.success;
    return project;
  });
}

export function buildProject(
  projectId,
  notify = false,
  skip_sentiment = false,
  dashboard_build = false,
  max_ngram_length = 5
) {
  return apiV5.post(`/projects/${projectId}/build/`, {
    notify,
    skip_sentiment,
    dashboard_build,
    max_ngram_length
  });
}

export function buildSentiment(projectId, sentiment_only = true) {
  return apiV5.post(`/projects/${projectId}/build/`, {
    sentiment_only
  });
}

export function copyProject(params) {
  const {
    projectId,
    filter,
    selection,
    matchType,
    workspaceId,
    name,
    description,
    shouldNotify,
    skipRebuild
  } = params;

  const hasFilter = filter != null ? filter.length : undefined;
  const search = selection?.toJSON();

  const apiParams = {
    workspace_id: workspaceId,
    name,
    description,
    ...(hasFilter && { filter }),
    ...(search && { search }),
    ...(search && { match_type: matchType }),
    ...((hasFilter || search) && shouldNotify && { notify: true }),

    // the skip_rebuild param is deprecated and set to be removed in the future:
    // http://daylight-master.dev.int.luminoso.com/api/v5/#Behavior%20change%20and%20deprecation:%20skip_rebuild%20on%20%22Copy%20project%22
    //
    // for now we set the parameter to false to match the original behavior
    // before this deprecation.
    skip_rebuild: skipRebuild || false
  };

  return apiV5.post(`/projects/${projectId}/copy/`, apiParams);
}

export function getBuildStatus(projectId) {
  return apiV5
    .get(`/projects/${projectId}/`)
    .then(({ last_build_info: { stop_time: stopTime, success, reason } }) => {
      const status = stopTime != null ? (success ? 'OK' : 'ERROR') : 'PENDING';
      return { status, reason };
    });
}

export function getFilterInfo(projectId, filter = []) {
  return apiV5
    .post(`/projects/${projectId}/concepts/`, {
      concept_selector: { limit: 500, type: 'top' },
      filter
    })
    .then(response => {
      const concepts = response.result
        .filter(concept => concept.vectors?.length > 0)
        .map(Concept.fromJSON);
      return { filterCount: response.filter_count, concepts };
    });
}

export function getMetadata(projectId) {
  return apiV5
    .get(`/projects/${projectId}/metadata/`, { max_values: 10000 })
    .then(response => response.result.map(MetadataField.fromJSON));
}

export function getSearchDetails(projectId, concept, filter = []) {
  const search = extractSearchFromConcept(concept);

  if (search && getFeatureFlags().boolean_search) {
    const params = { search, filter };
    return apiV5
      .post(`/projects/${projectId}/concepts/search_details/`, params)
      .then(response =>
        _.mapValues(camelCaseKeys(response), section =>
          section.concepts
            ? {
                ...section,
                concepts: section.concepts.map(Concept.fromJSON)
              }
            : section
        )
      );
  }

  const conceptSelector = search
    ? { limit: 50, type: 'related', search_concept: search }
    : { limit: 50, type: 'top' };

  return apiV5
    .post(`/projects/${projectId}/concepts/match_counts/`, {
      filter,
      concept_selector: conceptSelector
    })
    .then(response => response.match_counts.map(Concept.fromJSON));
}

export function getDocs(
  projectId,
  concept,
  filter,
  match_type = null,
  isSentimentReady = false,
  limit = 50,
  offset = 0
) {
  const search = extractSearchFromConcept(concept);
  if (filter == null) {
    filter = [];
  }
  return apiV5
    .get(`/projects/${projectId}/docs/`, {
      filter,
      limit,
      offset,
      ...(match_type && { match_type }),
      ...(search && { search }),
      include_sentiment_on_concepts: isSentimentReady
    })
    .then(response => {
      const searchedConcept =
        response.search && Concept.fromJSON(response.search);
      return {
        matches: response.result
          .filter(doc => doc.title?.trim() || doc.text.trim())
          .map(Doc.fromJSON),
        search: searchedConcept,
        filterDocCount: response.result_count
      };
    });
}

export function deleteDocs(projectId, doc_ids) {
  return apiV5.post(`/projects/${projectId}/docs/delete/`, {
    doc_ids
  });
}

export function getConcepts(projectId, conceptSelector, filter = []) {
  const params = {
    concept_selector: conceptSelector,
    ...(filter.length > 0 && { filter })
  };

  return apiV5
    .get(`/projects/${projectId}/concepts/`, params)
    .then(response => ({
      ...camelCaseKeys(response),
      result: response.result.map(Concept.fromJSON)
    }));
}

export function getConceptCounts(
  projectId,
  conceptSelector,
  filter = [],
  breakdowns = []
) {
  const params = {
    concept_selector: conceptSelector,
    ...(filter.length > 0 && { filter }),
    ...(breakdowns.length > 0 && {
      breakdowns: breakdowns.map(breakdown => breakdown.toAPIObject())
    })
  };

  return apiV5
    .get(`/projects/${projectId}/concepts/match_counts/`, params)
    .then(response => ({
      ...camelCaseKeys(response),
      matchCounts: response.match_counts.map(Concept.fromJSON),
      breakdowns: response.breakdowns.map(breakdown => ({
        breakdown: breakdown.breakdown,
        buckets: breakdown.buckets.map(bucket => ({
          ...bucket,
          match_counts: bucket.match_counts.map(Concept.fromJSON)
        }))
      }))
    }));
}

export function getSpecifiedMatchCounts(projectId, keys, filter, matchType = 'both') {
  const concepts = keys.map(key => ({ texts: [key] }));
  const isTotal = matchType === 'both'
  return apiV5
    .post(`/projects/${projectId}/concepts/match_counts/`, {
      concept_selector: {
        type: 'specified',
        concepts
      },
      filter
    })
    .then(response =>
      response.match_counts.reduce((acc, item) => {
        acc[item.name] = {
          matchCount: isTotal ? item.match_count: item.exact_match_count
        };

        return acc;
      }, {})
    );
}

export function getConceptCountsOutliers(
  projectId,
  conceptSelector,
  filter = [],
  breakdowns = []
) {
  const outlierConstraint = new OutlierConstraint();
  const params = {
    concept_selector: { type: 'top', limit: 10 },
    filter: [...filter, outlierConstraint],
    ...(breakdowns.length > 0 && {
      breakdowns: breakdowns.map(breakdown => breakdown.toAPIObject())
    })
  };

  return apiV5
    .get(`/projects/${projectId}/concepts/match_counts/`, params)
    .then(response => ({
      ...camelCaseKeys(response),
      matchCounts: response.match_counts.map(conceptData => {
        const enhancedConceptData = { ...conceptData, outlier: true };
        return Concept.fromJSON(enhancedConceptData);
      }),
      breakdowns: response.breakdowns.map(breakdown => ({
        breakdown: breakdown.breakdown,
        buckets: breakdown.buckets.map(bucket => ({
          ...bucket,
          match_counts: bucket.match_counts.map(conceptData => {
            const enhancedConceptData = { ...conceptData, outlier: true };
            return Concept.fromJSON(enhancedConceptData);
          })
        }))
      }))
    }));
}

export function createProject(workspaceId, name, language, description) {
  return apiV5.post('/projects/', {
    workspace_id: workspaceId,
    name,
    language,
    ...(description != null && { description })
  });
}

export function editProject(projectId, { name, language, description }) {
  const params = _.pickBy({ name, language, description }, v => v != null);
  return apiV5.put(`/projects/${projectId}/`, params);
}

export function uploadDocs(projectId, docs, progressCallback) {
  const batchSize = 1000;
  let numberUploaded = 0;
  const formatDoc = doc => ({
    text: '',
    ...doc,
    metadata: doc.metadata.map(field => {
      switch (field.type) {
        case MetadataTypes.DATE:
          return { ...field, value: field.value.toISOString() };
        default:
          return field;
      }
    })
  });

  let promise = Promise.resolve();
  for (let i = 0; i < docs.length; i += batchSize) {
    (batch =>
      (promise = promise.then(() =>
        apiV5
          .post(`/projects/${projectId}/upload/`, {
            docs: batch.map(formatDoc)
          })
          .then(() => {
            numberUploaded += batch.length;
            return typeof progressCallback === 'function'
              ? progressCallback(numberUploaded)
              : undefined;
          })
      )))(docs.slice(i, i + batchSize));
  }

  return promise;
}

export function downloadDocuments(
  projectId,
  projectName,
  selection,
  filter,
  extension,
  matchType = 'both'
) {
  const name = projectName.substring(0, 25);
  const description = `Documents matching ${selection?.name ?? 'filter'}`;
  const filename = `${name} - ${description}.${extension}`;

  return apiV5.downloadSpreadsheet(
    `/projects/${projectId}/docs/`,
    {
      filter,
      limit: 20000,
      format: extension,
      match_type: matchType,
      ...(selection && { search: selection.toJSON() })
    },
    filename,
    false
  );
}

export function downloadConceptFilterAssociations(
  projectId,
  projectName,
  filters
) {
  return apiV5.downloadSpreadsheet(
    `/projects/${projectId}/concepts/filter_associations/`,
    {
      filters,
      format: 'xlsx',

      concept_selector: { type: 'concept_list', concept_list_id: 'active' }
    },
    getExportFilename(projectName, 'ConceptFilter')
  );
}

export function downloadConceptConceptAssocScores(projectId, projectName) {
  return apiV5.downloadSpreadsheet(
    `/projects/${projectId}/concepts/concept_associations/`,
    {
      format: 'xlsx',
      concept_selector: { type: 'concept_list', concept_list_id: 'active' }
    },
    getExportFilename(projectName, 'ConceptConcept')
  );
}

export function downloadVolumeSpreadsheet(
  projectId,
  projectName,
  conceptSelector,
  filter,
  breakdowns = []
) {
  return apiV5.downloadSpreadsheet(
    `/projects/${projectId}/concepts/match_counts/`,
    {
      format: 'xlsx',
      concept_selector: conceptSelector,
      filter,
      breakdowns: breakdowns.map(breakdown => breakdown.toAPIObject())
    },
    getExportFilename(projectName, 'Volume')
  );
}

export function getSuggestedClusters(projectId) {
  const groupClusters = concepts =>
    _.values(_.groupBy(concepts, 'clusterLabel'));

  const getClusterMatchCount = cluster => _.sumBy(cluster, 'matchCount');

  const sortClustersByMatchCount = clusters =>
    _.orderBy(clusters, getClusterMatchCount, 'desc');

  return apiV5
    .get(`/projects/${projectId}/concepts/match_counts/`, {
      concept_selector: { type: 'suggested' }
    })
    .then(({ match_counts: matchCounts }) => {
      const concepts = matchCounts.map(Concept.fromJSON);
      const clusters = groupClusters(concepts);
      return sortClustersByMatchCount(clusters);
    });
}

export function getSuggestions(projectId) {
  return getSuggestedClusters(projectId).then(clusters => {
    const attachColors = (cluster, clusterIndex) =>
      cluster.map(concept => concept.update({ color: COLORS[clusterIndex] }));
    return _.flatten(clusters.map(attachColors));
  });
}

export function getActiveConceptList(projectId) {
  return apiV5
    .get(`/projects/${projectId}/concept_lists/active/`, {
      include_science: true
    })
    .then(({ name, concepts }) => {
      return { name, concepts: concepts.map(Concept.fromJSON) };
    });
}

export function addActiveConcepts(projectId, ...concepts) {
  return apiV5
    .post(`/projects/${projectId}/concept_lists/active/concepts/`, {
      include_science: true,
      concepts: concepts.map(concept => concept.formatForSaving())
    })
    .then(concepts => concepts.map(Concept.fromJSON));
}

export function removeActiveConcepts(projectId, ...sharedConceptIds) {
  return apiV5.del(`/projects/${projectId}/concept_lists/active/concepts/`, {
    shared_concept_ids: sharedConceptIds
  });
}

export function reorderActiveConcepts(projectId, sharedConceptIds) {
  return apiV5.put(
    `/projects/${projectId}/concept_lists/active/concepts/order/`,
    { order: sharedConceptIds }
  );
}

export function updateActiveConcepts(projectId, concepts, properties = null) {
  return apiV5
    .put(`/projects/${projectId}/concept_lists/active/concepts/`, {
      include_science: true,
      concepts: concepts.map(concept => {
        let update = concept.formatForSaving();
        if (properties) {
          update = _.pick(update, ['shared_concept_id', ...properties]);
        }
        return update;
      })
    })
    .then(concepts => concepts.map(Concept.fromJSON));
}

export function getSharedConceptLists(projectId) {
  return apiV5.get(`/projects/${projectId}/concept_lists/`);
}

export function openSharedConceptList(projectId, conceptListId) {
  return apiV5.put(`/projects/${projectId}/concept_lists/active/replace/`, {
    concept_list_id: conceptListId
  });
}

export function deleteSharedConceptList(projectId) {
  return apiV5.del(`/projects/${projectId}/concept_lists/active/`);
}

export function deleteSharedConceptListById(projectId, conceptListId) {
  return apiV5.del(`/projects/${projectId}/concept_lists/${conceptListId}/`);
}

export function restoreSharedConceptList(
  projectId,
  name,
  concepts,
  overwrite = false
) {
  // Use create endpoint to undo deleting or modifying a shared concept list
  return apiV5.post(`/projects/${projectId}/concept_lists/`, {
    name,
    // Pull out all properties that the api will accept
    concepts: concepts.map(c => _.pick(c, ['name', 'texts', 'color'])),
    overwrite
  });
}

export function renameSharedConceptList(projectId, newName, overwrite) {
  return apiV5.post(`/projects/${projectId}/concept_lists/active/rename/`, {
    new_name: newName,
    overwrite
  });
}

export function renameActiveConceptList(projectId, name) {
  return apiV5.put(`/projects/${projectId}/concept_lists/active/`, { name });
}

export function createConceptList(projectId, name, overwrite) {
  return apiV5.post(`/projects/${projectId}/concept_lists/active/share/`, {
    name,
    overwrite
  });
}

export function copyConceptList(projectId, sourceProjectId, overwrite = false) {
  return apiV5.post(`/projects/${projectId}/concept_lists/import/`, {
    source_project_id: sourceProjectId,
    overwrite
  });
}

export function restoreActiveConceptList(projectId, name, concepts) {
  return apiV5
    .put(`/projects/${projectId}/concept_lists/active/restore/`, {
      name,
      concepts: concepts.map(concept => concept.deactivate().formatForSaving())
    })
    .then(({ name, concept_list_id, concepts }) => ({
      name,
      concept_list_id,
      concepts: concepts.map(Concept.fromJSON)
    }));
}

export function calculateAverageScore(
  projectId,
  matchType,
  filter,
  conceptSelector
) {
  const params = {
    filter,
    // type: conceptSelector.type,
    // top: 22,
    // limit: 22,
    ...(conceptSelector
      ? { concept_selector: conceptSelector }
      : { limit: 50 }),
    match_type: matchType
    // score_field: conceptSelector.score_field,
  };

  console.log(conceptSelector, 'conceptSelector');
  return apiV5
    .get(`/projects/${projectId}/concepts/score_drivers/`, params)
    .then(response => {
      console.log(response, 'response');
      return response
        .map(Concept.fromJSON)
        .filter(driver => driver.confidence !== 0);
    });
}

export function getDrivers(
  projectId,
  filter,
  scoreField,
  conceptSelector,
  matchType
) {
  if (typeof scoreField === 'object') {
    scoreField = scoreField.name;
  }
  const params = {
    score_field: scoreField,
    filter,
    ...(conceptSelector
      ? { concept_selector: conceptSelector }
      : { limit: 50 }),
    match_type: matchType
  };
  return apiV5
    .get(`/projects/${projectId}/concepts/score_drivers/`, params)
    .then(response => {
      return response
        .map(Concept.fromJSON)
        .filter(driver => driver.confidence !== 0);
    });
}

export function getDriversOutliers(
  projectId,
  filter,
  scoreField,
  conceptSelector,
  matchType
) {
  if (typeof scoreField === 'object') {
    scoreField = scoreField.name;
  }
  const outlierConstraint = new OutlierConstraint();
  const params = {
    score_field: scoreField,
    filter: [...filter, outlierConstraint],
    concept_selector: { type: 'top', limit: 10 },
    match_type: matchType
  };
  return apiV5
    .get(`/projects/${projectId}/concepts/score_drivers/`, params)
    .then(response => {
      return response
        .map(driver => {
          const enhancedDriver = { ...driver, outlier: true };
          return Concept.fromJSON(enhancedDriver);
        })
        .filter(driver => driver.confidence !== 0);
    });
}

export function downloadDrivers(
  projectId,
  projectName,
  conceptSelector,
  scoreField,
  filter = []
) {
  const params = {
    format: 'xlsx',
    score_field: scoreField,
    filter,
    ...(conceptSelector.type === 'drivers_suggested' &&
    conceptSelector.score_field === scoreField
      ? { limit: conceptSelector.limit }
      : { concept_selector: conceptSelector })
  };

  return apiV5.downloadSpreadsheet(
    `/projects/${projectId}/concepts/score_drivers/`,
    params,
    `${projectName.substring(0, 25)}_${scoreField}_ScoreDrivers.xlsx`
  );
}

export async function getSentiment(projectId, conceptSelector, filter = []) {
  const response = await apiV5.get(`/projects/${projectId}/concepts/sentiment/`, {
    filter,
    include_examples: true,
    concept_selector: conceptSelector,
  });

  const { match_counts, ...restResponse } = response;
  const matches = await Promise.all(
    match_counts.map(async ({ example_documents, ...sentimentConcept }) => {
      try {
        if (sentimentConcept.type === "and") {
          const transformedArray = sentimentConcept.texts.map(item => ({
            texts: [item],
          }));

          const newVar = await getSpecifiedSentimentConcepts(projectId, transformedArray);

          sentimentConcept.andConcepts = newVar.matches;
        }
      } catch (error) {
        console.error("Error fetching additional data for concept:", sentimentConcept, error);
      }
      return Concept.fromJSON({
        ...sentimentConcept,
        exampleDocuments: example_documents?.map(Doc.fromJSON),
      });
    })
  );

  return {
    ...camelCaseKeys(restResponse),
    matches,
  };
}



export function getSentimentOutliers(projectId, conceptSelector, filter = []) {
  const outlierConstraint = new OutlierConstraint();
  return apiV5
    .get(`/projects/${projectId}/concepts/sentiment/`, {
      filter: [...filter, outlierConstraint],
      include_examples: true,
      concept_selector: { type: 'top', limit: 10 }
    })
    .then(({ match_counts, ...response }) => ({
      ...camelCaseKeys(response),
      matches: match_counts.map(
        ({ example_documents, ...sentimentConcept }) => {
          return Concept.fromJSON({
            ...sentimentConcept,
            exampleDocuments: example_documents?.map(Doc.fromJSON),
            outlier: true
          });
        }
      )
    }));
}

export function downloadSentiment(
  projectId,
  projectName,
  concept_selector,
  filter = [],
  selection = null
) {
  const params = { format: 'xlsx', filter, concept_selector };
  if (selection) {
    params.search = selection;
  }

  return apiV5.downloadSpreadsheet(
    `/projects/${projectId}/concepts/sentiment/`,
    params,
    `${projectName.substring(0, 25)}_SentimentScore.xlsx`
  );
}

export function deleteProject(projectId) {
  return apiV5.del(`/projects/${projectId}`);
}

export function getWorkspaces() {
  return apiV5.get('/profile/').then(({ default_workspace, workspaces }) => ({
    default_workspace,
    workspaces
  }));
}

export function getSharedViews(projectId) {
  return apiV5.get(`/projects/${projectId}/shared_views/`);
}

export function saveSharedView(projectId, shared_view) {
  return apiV5.post(`/projects/${projectId}/shared_views/`, { shared_view });
}

export function deleteSharedView(projectId, shared_view_id) {
  return apiV5.del(`/projects/${projectId}/shared_views/${shared_view_id}`);
}

export function getConceptManagementInformation(projectId) {
  return apiV5
    .get(`/projects/${projectId}/concepts/manage/`)
    .then(response => new ConceptManagementInformation(response));
}

export function updateConceptManagementInformation(
  projectId,
  nextBuildUpdates
) {
  return apiV5.put(`/projects/${projectId}/concepts/manage/`, {
    concept_management: nextBuildUpdates,
    overwrite: true
  });
}

export const getExternalToken = token => {
  const payload = { auth_token: token };
  return apiV5.post('/external_login', payload, null);
};

export function getScienceChangeLog() {
  return apiV5.get(`/science_changelog/`);
}

export const createRedditTaskDashboard = (
  projectId,
  reddit_list,
  repeat,
  repeat_interval,
  apiToken,
  should_notify=false,
  skip_sentiment=false
) => {
  return postWithFullUrl(
    `${process.env.DASHBOARDS_URL_STREAM_DATA}/projects/${projectId}/fetchdata`,
    {
      fetch_type: 'reddit',
      fetch_config: { reddit_list },
      repeat,
      repeat_interval,
      token:
        apiToken || localStorage.getItem('auth_token').replace(/['"]+/g, ''),
      notify: should_notify,
      skip_sentiment
    },
    {
      'Access-Control-Request-Method': 'POST'
    }
  );
};

export const createReviewsTaskDashboard = (
  projectId,
  url_list,
  repeat,
  repeat_interval,
  apiToken,
  should_notify=false,
  skip_sentiment=false
) => {
  return postWithFullUrl(
    `${process.env.DASHBOARDS_URL_STREAM_DATA}/projects/${projectId}/fetchdata`,
    {
      fetch_type: 'reviews',
      fetch_config: { url_list },
      repeat,
      repeat_interval,
      token:
        apiToken || localStorage.getItem('auth_token').replace(/['"]+/g, ''),
      notify: should_notify,
      skip_sentiment
    },
    {
      'Access-Control-Request-Method': 'POST'
    }
  );
};

export const createScrapersV2Task = (
  project_id,
  url_list,
  repeat,
  repeat_interval,
  token,
  should_notify,
  skip_sentiment
) => {
  // const scrapersUrlBase =
  //   process.env.SCRAPERS_URL || 'http://scrapers-dev.int.luminoso.com';
  const scrapersUrlBase =
    process.env.SCRAPERS_URL || 'https://scrapers-dev.luminoso.com';

  // determine api_base
  //
  // // doesnt seem to be working on master or prod
  // const api_base = process.env.API_BASE
  //
  const apiBase = window.location.protocol + '//' + window.location.host;

  const request = new Request(`${scrapersUrlBase}/scrape`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      project_id,
      url_list,
      repeat,
      repeat_interval,
      token: token || localStorage.getItem('auth_token').replace(/['"]+/g, ''),
      should_notify,
      skip_sentiment,
      api_base: apiBase
    })
  });

  return fetch(request).then(response => {
    console.log('response');
    console.log(response);

    return response;
  });
};

export async function getDashboardProject(projectId) {
  try {
    return await getWithFullUrl(
      `${process.env.DASHBOARDS_URL_STREAM_DATA}/projects/${projectId}`
    );
  } catch (e) {
    console.error(e);
  }
}

export async function getProjectTasks(projectId) {
  const res = await getWithFullUrl(
    `${process.env.DASHBOARDS_URL_STREAM_DATA}/projects/${projectId}/tasks`
  );
  return res?.task_list;
}

export async function removeProjectTask(projectId, taskId) {
  const res = await deleteWithFullUrl(
    `${process.env.DASHBOARDS_URL_STREAM_DATA}/projects/${projectId}/tasks`,
    { task_id: taskId }
  );
  return res?.task_id;
}

export function setOutlierFilter(projectId, matchType = 'both') {
  const params = {
    concept_selector: { type: 'concept_list', concept_list_id: 'active' },
    match_type: matchType
  };

  return apiV5.post(`/projects/${projectId}/concepts/outliers`, params);
}

export function getSpecifiedConcepts(projectId, concepts) {
  return apiV5
    .post(`/projects/${projectId}/concepts/`, {
      concept_selector: { type: 'specified', concepts }
    })
    .then(response => {
      const concepts = response.result.map(Concept.fromJSON);
      return { filterCount: response.filter_count, concepts };
    });
}
export function getSpecifiedSentimentConcepts(projectId, concepts) {
  return apiV5
    .post(`/projects/${projectId}/concepts/sentiment`, {
      concept_selector: { type: 'specified', concepts },
      include_examples: true,
    })
    .then(({ match_counts, ...response }) => ({
      ...camelCaseKeys(response),
      matches: match_counts.map(
        ({ example_documents, ...sentimentConcept }) => {
          return Concept.fromJSON({
            ...sentimentConcept,
            exampleDocuments: example_documents?.map(Doc.fromJSON),
          });
        }
      )
    }));
}


export function getChatbotInformation(projectId) {
  return apiV5.get(`/projects/${projectId}/chatbot/data/`);
}

export function chatbotGenerate(projectId, prompt) {
  const params = { prompt };
  return apiV5.get(`/projects/${projectId}/chatbot/generate/`, params);
}

export function buildChatbotData(projectId) {
  return apiV5.post(`/projects/${projectId}/chatbot/build/`);
}

export function chatbotThemes(projectId) {
  return apiV5.get(`/projects/${projectId}/chatbot/themes/`);
}

export function putConceptsTranslateValue(projectId, value) {
  const params = {"value": value};
  return apiV5.put(`/projects/${projectId}/concepts/translate_en/`, params);
}

export function getConceptsTranslate(projectId) {
  return apiV5.get(`/projects/${projectId}/concepts/translate_en/`);
}

export function postConceptsTranslateBuild(projectId) {
  return apiV5.post(`/projects/${projectId}/concepts/translate_en_build/`);
}

export function getHeliosVersion(projectId) {
  return apiV5.get(`/projects/${projectId}/helios/version/`);
}

export function putHeliosVersion(projectId, version) {
  return apiV5.put(`/projects/${projectId}/helios/version/`,
    {backend_version: version}
  );
}

export function getHeliosLlmSelection(projectId) {
  return apiV5.get(`/projects/${projectId}/helios/llmselection/`);
}

export function putHeliosLlmSelection(projectId, llmselection) {
  return apiV5.put(`/projects/${projectId}/helios/llmselection/`,
    {llmselection}
  );
}
